@import '../../styles/index';

.base {
  align-items: center;
  background-color: rgba($color-surrogate--100, 0.05);
  border-radius: $border-radius-small;
  color: $color-surrogate--100;
  display: flex;
  font-size: $font-size-20;
  font-weight: $font-weight-medium;
  justify-content: center;
  line-height: 1.5;
  margin: 0;
  text-align: center;
  width: 100%;
  padding: 1.5rem;

  & > * {
    margin: 0.5rem;
  }
}
